import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonField from '../../../Common/FormFields/ButtonField'

const Subscription = () => {
    const { t } = useTranslation()
    const subscriptionmapping = [
        {
            Premium: "Premium",
            date: "12/04/2022",
            daysleft: "29 days"
        }
    ]
    return (
        <div className={`bg-white mt-3 h-screen p-5`}>
            {subscriptionmapping.map((item, idx) =>
                <div className={`bg-white  border border-[#E8E8E8] flex justify-between w-full p-5`} key={idx}>
                    <div>
                        <p className={`text-[#413B3B] font-[Roboto-Regular] text-base`}>{t("activeSubscriptionPlan")}: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{item.Premium}</span> </p>
                        <p className={`text-[#413B3B] font-[Roboto-Medium] text-base`}>{t("nextBillingDate")}: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{item.date}</span> </p>
                    </div>
                    <div className={`flex`}>
                        <ButtonField
                            buttonextracls={`bg-blue-0.5  text-blue-5 p-3 mr-4 `}
                            buttonName="cancelYourPlan"
                            buttonnamecls={`pl-2  text-sm font-[Roboto-Medium]`}
                        />
                        {/* <ButtonField
                        buttonextracls={`border-1 border-deepgray-4 bg-white text-black p-3 text-xs font-[Roboto-Medium]`}
                        buttonName={props.buttonName3}
                        img={props.buttonimg2}
                        variant="outlined"
                        buttonnamecls={`pl-2 text-[10px] font-[Roboto-Medium]`}
                    /> */}
                        <p className={`bg-skyblue-6 text-white text-sm uppercase font-[Roboto-Medium] w-20 text-center h-10 flex items-center justify-center rounded`}>{item.daysleft}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Subscription