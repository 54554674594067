import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import MyReqExpiredBid from './MyReqExpiredBid'
import MyReqFormForVendor from './MyReqFormForVendor'
import MyReqLiveBid from './MyReqLiveBid'
import MyReqSelectedBid from './MyReqSelectedBid'
import VendorMyReqLive from './VendorMyReqLive'
import MyReqActive from './MyReqActive'
import MyReqDenied from './MyReqDenied'
import MyReqPending from './MyReqPending'

const VendorMyReqPage = (props) => {
    const liveMyRequirementsData = useSelector((state) => state.vendor.myRequirementByVendorId)

    useEffect(() => {
        props?.getCategoryInVendor()
    }, [])

    useEffect(() => {
        if (props.value === '0') {
            props.getVendorRequirementsByVendorId('live')
        } 
        else if (props.value === '1') {
            props.getVendorRequirementsByVendorId('active')
        } 
        else if (props.value === '2') {
            props.getVendorRequirementsByVendorId('pending')
        }
        else if (props.value === '3') {
            props.getVendorRequirementsByVendorId('denied')
        }  
        else if (props.value === '4') {
            props.getVendorRequirementsByVendorId('expire')
        }
    }, [props.value])

    const categories = [
        {
            label: "live",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqLiveBid
                goToMyReqrLiveDetails={props.goToMyReqrLiveDetails}
                liveMyRequirementsData={liveMyRequirementsData}
                loading={props.loading}
            />
        },
        {
            label: "active",
            value: "1",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqActive
                goToMyReqrLiveDetails={props.goToMyReqrLiveDetails}
                liveMyRequirementsData={liveMyRequirementsData}
                loading={props.loading}
            />
        },
        {
            label: "pending",
            value: "2",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqPending
                goToMyReqrLiveDetails={props.goToMyReqrLiveDetails}
                liveMyRequirementsData={liveMyRequirementsData}
                loading={props.loading}
            />
        },
        {
            label: "denied",
            value: "3",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqDenied
                goToMyReqrLiveDetails={props.goToMyReqrLiveDetails}
                liveMyRequirementsData={liveMyRequirementsData}
                loading={props.loading}
            />
        },
        {
            label: "expired",
            value: "4",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqExpiredBid
                goToMyReqrLiveDetails={props.goToMyReqrLiveDetails}
                liveMyRequirementsData={liveMyRequirementsData}
                loading={props.loading}
            />
        },
        {
            label: "selectedVendor",
            value: "5",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqSelectedBid saveReqData={props.saveReqData} getBidApiCAllByReqId={props.getBidApiCAllByReqId} />
        },
    ]

    return (
        <div className={` mt-3 colorformyreqtab`}>
            {props.showMyReqrForm ?
                (
                    <MyReqFormForVendor
                        createVendorMyRequirements={props.createVendorMyRequirements}
                        goBackToMyRequirementTab={props.goBackToMyRequirementTab}
                        toggleMyRequirement={props.toggleMyRequirement}
                        showMyReqrForm={props.showMyReqrForm}
                        myRequirementCreate={props?.myRequirementCreate}
                        getCategoryInVendor={props?.getCategoryInVendor}
                        // doUploadedMedia={props.doUploadedMedia}
                        multipleMediaApiCall={props.multipleMediaApiCall}
                        loading={props.loading}
                        reqError={props.reqError}
                    />
                ) : (
                    props.showMyReqrLiveDetails ?
                        <VendorMyReqLive
                            goBackFromMyReqrLiveDetails={props.goBackFromMyReqrLiveDetails}
                            liveRequirementsData={liveMyRequirementsData}
                            saveReqData={props.saveReqData}
                            downloadPdfAttachments={props.downloadPdfAttachments}
                            getBidApiCAllByReqId={props.getBidApiCAllByReqId}
                            singleVendorDetailsData={props.singleVendorDetailsData}
                            selectBidsApiCall={props.selectBidsApiCall}
                            loading={props.loading}
                        />
                        :
                        <TabComponent
                            mappingname={categories}
                            button={true}
                            buttonextracls={`bg-skyblue-6  text-white rounded text-sm mt-2 mr-4 capitalize`}
                            buttonname="addNew"
                            onClick={props.toggleMyRequirement}
                            value={props.value}
                            setValue={props.setValue}
                        />
                )
            }
        </div>
    )
}

export default VendorMyReqPage