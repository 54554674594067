import React from 'react'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import SubscriptionTable from './SubscriptionTable'
import text from '../../../Common/Languages/en_US.json'


const SubscriptionComponent = () => {
    return (
        <div>
            <TotalUsersHeader userName={text.subscription} />
            <div className={`overflow-y-auto h-[75vh]`}>
                <SubscriptionTable />
            </div>
        </div>
    )
}

export default SubscriptionComponent