// import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StepperLayout from '../../../Common/Layout/StepperLayout/StepperLayout'
import CreateAccount from '../../../Components/Authentication/Signup/CreateAccount'
// import CreateAddress from '../../../Components/Authentication/Signup/CreateAddress'
import { ForSignup } from './Hook'
import MainHeader from '../../../Common/Layout/MainHheader/MainHeader'
import ApprovalPandingPage from '../../../Components/Authentication/Signup/ApprovalPandingPage'
// import SubscriptionPlan from '../../../Components/Authentication/Signup/SubscriptionPlan'
import { ForUploadMediaHooks } from '../../UploadMedia/Hooks'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'

const SignupIndex = (props) => {
  const { EmailOTP,
    EmailOTPVerify,
    CreateAccountVendor,
    emailVerified,
    phoneNoVerified,
    showAddAddress,
    // GetAllVendorAccount,
    addAddressData,
    businessAddAddress,
    gotoApproval,
    doneApproval,
    autoFillOtp,
    goToSubscription,
    // getbusinessAddressDetails,
    emailErrorTexts,
    PhoneOTP,
    PhoneOTPVerify,
    createAccountForm,
    showOTP,
    handleClickForOTPMobile,
    handleClickForOTP,
    showOTPMobile,
    verifyotp,
    handleClickForChooseBussiness,
    colorForBussiness,
    handleClickForChooseIndivisual,
    colorForIndivisual,
    uniqueid,
    handleChecking,
    toggleCheck,
    OTPForMobile,
    setOTPForMobile,
    OTPForEmail,
    setOTPForEmail,
    check,
    typecheck,
    loading,
    messageClose,
    notificationSignup,
    mapLocationTxt,
    setMapLocationTxt,
    mapLocation,
    handleClickLocation,
    phoneChangeHandler,
    phoneNo,
    phoneNoError,
    showEmailOtpButtonVendor, 
    setShowEmailOtpButtonVendor,
    showPhoneOtpButtonVendor, 
    setShowPhoneOtpButtonVendor
  } = ForSignup()

  const { doUploadedMedia, doUploadedLogo, multipleMediaApiCall } = ForUploadMediaHooks()

  useEffect(() => {
    // GetAllVendorAccount()
    // getbusinessAddressDetails()
  }, [])




  return (
    <>
      <div className={`sticky top-0 z-10`}>
        <MainHeader />
      </div>
      <div className={`flex flex-row`}>
        <StepperLayout doneSignup={gotoApproval}
          doneApproval={doneApproval} />
        <>
          {gotoApproval ?
            <ApprovalPandingPage handleClick={goToSubscription} />
            :
            <CreateAccount
              EmailOTP={EmailOTP}
              PhoneOTP={PhoneOTP}
              EmailOTPVerify={EmailOTPVerify}
              PhoneOTPVerify={PhoneOTPVerify}
              CreateAccountVendor={CreateAccountVendor}
              verifyotp={verifyotp}
              emailVerified={emailVerified}
              phoneNoVerified={phoneNoVerified}
              showAddAddress={showAddAddress}
              businessAddAddress={businessAddAddress}
              addAddressData={addAddressData}
              doUploadedMedia={doUploadedMedia}
              doUploadedLogo={doUploadedLogo}
              multipleMediaApiCall={multipleMediaApiCall}
              autoFillOtp={autoFillOtp}
              emailErrorTexts={emailErrorTexts}
              createAccountForm={createAccountForm}
              handleClickForOTP={handleClickForOTP}
              showOTP={showOTP}
              handleClickForOTPMobile={handleClickForOTPMobile}
              showOTPMobile={showOTPMobile}
              handleClickForChooseBussiness={handleClickForChooseBussiness}
              colorForBussiness={colorForBussiness}
              colorForIndivisual={colorForIndivisual}
              handleClickForChooseIndivisual={handleClickForChooseIndivisual}
              uniqueid={uniqueid}
              handleChecking={handleChecking}
              toggleCheck={toggleCheck}
              OTPForMobile={OTPForMobile}
              setOTPForMobile={setOTPForMobile}
              OTPForEmail={OTPForEmail}
              setOTPForEmail={setOTPForEmail}
              check={check}
              typecheck={typecheck}
              loading={loading}
              mapLocationTxt={mapLocationTxt}
              setMapLocationTxt={setMapLocationTxt}
              mapLocation={mapLocation}
              handleClickLocation={handleClickLocation}
              phoneChangeHandler={phoneChangeHandler}
              phoneNo={phoneNo}
              phoneNoError={phoneNoError}
              showEmailOtpButtonVendor={showEmailOtpButtonVendor}
              setShowEmailOtpButtonVendor={setShowEmailOtpButtonVendor}
              showPhoneOtpButtonVendor={showPhoneOtpButtonVendor}
              setShowPhoneOtpButtonVendor={setShowPhoneOtpButtonVendor}
            />}
        </>
        <SnackBarComponent
          messageOpen={notificationSignup.open}
          messageClose={messageClose}
          notificationText={notificationSignup.message}
          subText={notificationSignup.subText}
          alertType={notificationSignup.alertType}
          borderClass={notificationSignup.borderClass}
        />
      </div>
    </>
  )
}

export default SignupIndex