import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ButtonField from '../FormFields/ButtonField';
import { useTranslation } from 'react-i18next';


export default function TabComponent(props) {
  const { t } = useTranslation()
  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  return (
    <div className={`!bg-white ${props.faqTab ? props.faqTab : 'tabcomponent'} `}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={props.value}>
          <Box sx={{ borderBottom: 4, borderColor: 'divider', position: "relative", display: "flex", justifyContent: "space-between" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {props.mappingname.map((item, idx) =>
                <Tab label={t(item.label)} value={item.value} />
              )}
            </TabList>
            {props.button ? (
              <ButtonField
                buttonName={t(props.buttonname)}
                onClick={props.onClick}
                buttonextracls={props.buttonextracls}
                type={props.type}
              />
            )
              :
              null
            }
          </Box>
          {props.mappingname.map((item, idx) =>
            <TabPanel value={item.value} className={item.backgroundcolor}>{item.content}</TabPanel>
          )}

        </TabContext>
      </Box>
    </div>
  );
}