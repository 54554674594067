import { Grid } from '@mui/material'
import React, { useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import circleicon from '../../../Images/accountcreate/circle.svg'
import bluetickicon from '../../../Images/accountcreate/Bluetick.svg'
import purpletick from '../../../Images/accountcreate/purpletick.svg'
import texts from '../../../Common/Languages/en_US.json'
const SubscriptionPlan = () => {

    const [tickForBasic, setTickForBasic] = useState(false)
    const [tickForPremium, setTickForPremium] = useState(false)

    const handleclickforselectBasic = () => {
        setTickForBasic(!tickForBasic)
        setTickForPremium(false)

    }
    const handleclickforselectPremium = () => {
        setTickForPremium(!tickForPremium)
        setTickForBasic(false)
    }



    return (
        <Grid container spacing={5}>

            <Grid item xs={12} md={12}>
                <p className={`w-full h-[25%] flex justify-center items-center text-[2rem] text-Grey-900 font-[Roboto-Bold] leading-[2.375rem]`}>{texts.subscriptionPlans}</p>
                <div className={`flex flex-col lg:flex-row md:flex-col justify-center gap-8 items-center h-fit`}>

                    <div className={`border border-Grey-200 rounded-2xl pt-[2%] w-[80%] lg:w-[33%] md:w-[66%]`}>
                        <div className={`flex justify-between p-[8%]`}>
                            <p className={`pl-[2%] text-xl font-[Roboto-Medium] text-deepgray-4`}>{texts.basic}</p>
                            <img src={tickForBasic ? bluetickicon : circleicon} alt="icon" onClick={handleclickforselectBasic} className={``} />
                        </div>
                        <p className={`text-Gray-900 text-lg font-[Poppins-Medium] px-[8%] pb-[4%]`}>$<span className={`text-xl`}>200</span>.00</p>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.ItIsaLongEstablished}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}> {texts.theYearsSometimesByAccident}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.sometimesOnPurpose}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>({texts.injectedHumourAndTheLike}).</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.variousVersionsHaveEvolvedOver}</p>
                        </div>
                        <ButtonField
                            buttonextracls={` border-[1.5px] border-solid border-deepgray-0.5 capitalize w-full rounded-b-2xl h-14 ${tickForBasic ? "bg-skyblue-6" : "bg-skyblue-2 "} `}
                            buttonName="Proceed"
                            buttonnamecls={`font-[Roboto-Bold] text-white font-medium text-base`}
                            type="submit"
                            disabled={true}
                        // onClick={handleClick}
                        />
                    </div>

                    <div className={`border border-Grey-200 rounded-2xl pt-[2%] w-[80%] lg:w-[33%] md:w-[66%]`}>
                        <div className={`flex justify-between p-[8%]`}>
                            <p className={`pl-[2%] text-xl font-[Roboto-Medium] text-deepgray-4`}>{texts.premium}</p>
                            <img src={tickForPremium ? bluetickicon : circleicon} alt="icon" onClick={handleclickforselectPremium} className={``} />
                        </div>
                        <p className={`text-Gray-900 text-lg font-[Poppins-Medium] px-[8%] pb-[4%]`}>$<span className={`text-xl`}>200</span>.00</p>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.ItIsaLongEstablished}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.variousVersionsHaveEvolvedOver}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.theYearsSometimesByAccident}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>{texts.sometimesOnPurpose}</p>
                        </div>
                        <div className={`flex pb-[4%] px-[8%]`}>
                            <img src={purpletick} alt="purpletick" />
                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>({texts.injectedHumourAndTheLike}).</p>
                        </div>
                        <ButtonField
                            buttonextracls={` border-[1.5px] border-solid border-deepgray-0.5 capitalize w-full rounded-b-2xl h-14 ${tickForPremium ? "bg-skyblue-6" : "bg-skyblue-2 "} `}
                            buttonName="Proceed"
                            buttonnamecls={`font-[Roboto-Bold] text-white font-medium text-base`}
                            type="submit"
                            disabled={true}
                        // onClick={handleClick}
                        />
                    </div>

                </div>

            </Grid>
            {/* rightside end*/}

        </Grid>
    )
}

export default SubscriptionPlan