import React from 'react'
import SubscriptionComponent from '../../Components/Admin/Subscription'
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';

const SubscriptionIndex = () => {
    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div onClick={() => checkTokenExpiration()}><SubscriptionComponent /></div>
    )
}

export default SubscriptionIndex