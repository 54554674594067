import React, { useEffect } from 'react'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import OfferActivePage from './OfferActivePage'
import OfferPendingPage from './OfferPendingPage'
import OfferForm from './OfferForm'
import { useSelector } from 'react-redux'

const Offer = (props) => {
    const vendorProductOffers = useSelector((state)=> state.vendor.productOffers)
    // for filter by productId only 
    const vendorProductOffersByProductId = vendorProductOffers?.filter((item) => (item?.productId))

    useEffect(() => {
        if (props.value === '0') {
            props.getEachVendorProductOffers('active')
        } else if (props.value === '1') {
            props.getEachVendorProductOffers('pending')
        }
    },[props.value])
    
    const categories = [
        {
            label: "active",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <OfferActivePage 
                tabledata={vendorProductOffersByProductId} 
                loading={props.loading} 
                deleteProductOffers={props.deleteProductOffers}
            />
        },
        {
            label: "pending",
            value: "1",
            backgroundcolor: "bg-white",
            content: <OfferPendingPage 
                tabledata={vendorProductOffersByProductId} 
                editProductForm={props.editProductForm} 
                loading={props.loading}
                deleteProductOffers={props.deleteProductOffers}
            />
        },
    ]

    return (
        <div className={`mt-3 bg-white`}>
            {props.showOfferForm ?
                <OfferForm
                    toggleOffer={props.toggleOffer}
                    createNewProductOffers={props.createNewProductOffers}
                    dateState={props.dateState}
                    setDateState={props.setDateState}
                    showAddOfferForm={props.showAddOfferForm}
                    gobackToProductOffer={props.gobackToProductOffer}
                    multipleMediaApiCall={props.multipleMediaApiCall}
                    productSubmitError={props.productSubmitError}
                    loading={props.loading}
                    editProduct={props.editProduct}
                />
                :
                <TabComponent
                    mappingname={categories}
                    button={true}
                    buttonextracls={`bg-skyblue-6  text-white rounded text-sm mt-2 mr-4 capitalize`}
                    buttonname="addNew"
                    value= {props.value}
                    setValue = {props.setValue}
                    onClick={props.toggleOffer}
                />
            }
        </div>
    )
}

export default Offer