import React, { useState } from 'react'
import text from '../../../Common/Languages/en_US.json'
import directryLandingLogoBlack from "../../../Images/CommonImages/directryLandingLogoBlack.svg";
import { ClickAwayListener } from '@mui/material'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import ButtonField from '../../../Common/FormFields/ButtonField'
import { useNavigate } from 'react-router-dom'

const CodeVerification = (props) => {
    const [mailFocused, setMailFocused] = useState(false)
    const navigate = useNavigate()


  return (
      <div className={`bg-white flex items-center justify-center`}>
          <div className={`w-[70%] md:w-[50%] lg:w-[25%] my-auto  pt-[8%] lg:pt-[10%]`}>
              {/* login Welcome msg  */}
              <div className={`flex items-center flex-col justify-between gap-10`}>

                  <img src={directryLandingLogoBlack} alt='blue logo' className={`h-14 w-28`} onClick={() => navigate('/')}/>
                  <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{text.recieveEmail}</p>
                  <div className='text-center'>
                      <p className='text-primeryColour-4 font-[Roboto-Medium] text-[22px]'>{text.codeVerification}</p>
                      <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{text.enterOtp}</p>
                  </div>
              </div>
              {/* input fields */}
              <div className={`w-full flex flex-col ${props.emailErrorMessage || props.passwordErrorMessage || props.message ? 'gap-4' : 'gap-6'} mt-6 textMain`}>
                  <ClickAwayListener onClickAway={() => setMailFocused(false)}>
                      <div onClick={() => setMailFocused(true)} className='textField1'>
                          <TextFieldInput handelChange={(e) => props.setEmail(e)} floatingLabel={mailFocused === true ? '*Email ID' : null} focused={mailFocused} textnewclass={`w-full `} placeholder='*Enter Phone number or Email address' />
                          {props.emailErrorMessage && <p className={`error-message text-red-600 text-xs p-0 m-0`}>*{props.emailErrorMessage}</p>}
                      </div>
                  </ClickAwayListener>

                  <ButtonField
                      onClick={props.Login}
                      buttonName={text.Login}
                      buttonextracls={`!px-2 !py-2 !text-white  bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-full hover:bg-skyblue-6`}
                  />
                  {/* For showing the error message if login isnt't successful  */}
                  {props?.loginError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props?.loginError?.message}</p>}
                  <p className='text-deepgray-4 text-sm font-[Roboto-Medium]'>{text.backTo}&nbsp;
                      <span className='text-skyblue-6 font-[Roboto-Medium] text-sm cursor-pointer' onClick={() => navigate('/login')}>{text.Login}</span></p>
              </div>
          </div>

      </div>
  )
}

export default CodeVerification