import React from 'react'
import CheckBox from '../../../Common/FormFields/CheckBox';
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import usericon from '../../../Images/adminTable/userAvatar.svg'
import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import ButtonField from '../../../Common/FormFields/ButtonField';
import subscriptionAddBtn from '../../../Images/Subscription/SubscriptionAddImg.svg'


/**
 * @component SubscriptionTable
 * @description Subscription table
 * @param {*} props 
 */

const SubscriptionTable = () => {
    const rows = [
        { checkbox: <CheckBox />, id: 1, ownerName: 'Snow', businessName: 'Ahmed Exports', email: 'shawnr.leanos@gmail.com', validity: '20/01/23', planName: 'Premium', price: '$500.00', status: 'Expired', action: '' },
        { checkbox: <CheckBox />, id: 2, ownerName: 'Lannister', businessName: 'Ahmed Exports', email: 'shawnr.leanos@gmail.com', validity: '20/01/23', planName: 'Premium', price: '$500.00', status: 'Expiring', action: 42 },
        { checkbox: <CheckBox />, id: 3, ownerName: 'Lannister', businessName: 'Ahmed Exports', email: 'shawnr.leanos@gmail.com', validity: '20/01/23', planName: 'Premium', price: '$500.00', status: 'Active', action: 45 },
        { checkbox: <CheckBox />, id: 4, ownerName: 'Stark', businessName: 'Ahmed Exports', email: 'shawnr.leanos@gmail.com', validity: '20/01/23', planName: 'Premium', price: '$500.00', status: 'Active', action: 16 },
        { checkbox: <CheckBox />, id: 5, ownerName: 'Targaryen', businessName: 'Ahmed Exports', email: 'shawnr.leanos@gmail.com', validity: '20/01/23', planName: 'Premium', price: '$500.00', status: 'Expired', action: null },

    ];



    return (
        <div className='w-full border-[#EBEBEB]'>
            <table className="table-auto w-full ">

                <thead className='bg-white sticky top-0 z-10'>
                    <tr className='h-10'>
                        <th className={`w-[5%] text-[#828282] text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox />
                        </th>
                        <th className={`w-[12%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Owner Name</th>

                        <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Business Name</th>
                        <th className={`w-[13%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Email</th>
                        <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Validity</th>
                        <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Plan Name</th>
                        <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Price</th>
                        <th className={`w-[10%] text-[#585F71] text-left pr-8 text-base font-normal font-[Roboto-Regular] `}>
                            Status
                        </th>
                        <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>Action</th>

                    </tr>
                </thead>
                <tbody>

                    {rows?.map((item, idx) => {
                        return (
                            <tr
                                key={idx}
                                className={' drop-shadow-md border-t-2 border-b-2  border-[#EBEBEB]'}>
                                <td className={`text-left text-[14px] font-normal font-[SansRegular] px-[1%]`}>{item.checkbox}</td>
                                <div className='flex'>
                                    <img src={usericon} alt="usericon" height={42} width={42} />
                                    <td className={`text-left text-[#1F2533] text-sm py-6 pl-2 font-medium  font-[Roboto-Regular]`}>{item?.ownerName}</td>
                                </div>
                                <td className={`text-left text-[14px] font-normal font-[Roboto-Regular]`}>{item?.businessName}</td>
                                <td className={`text-left text-[#3677B1] text-[14px] font-normal font-[Roboto-Regular]`}>{item?.email}</td>
                                {/* <td>
                                    {copyBtn ?

                                        (

                                            <div className={`flex `}>
                                                <p className={`w-[170px] truncate text-left text-[14px] font-normal font-[SansRegular] lowercase pr-1 ${styles.itemsdata}`}>
                                                    {item?.createdBy?.email}
                                                </p>
                                                <img src={CopyIcon} height={15} width={15} alt="listitem" priority onClick={() => copyEmail(item?.createdBy?.email)} />
                                            </div>


                                        )
                                        :

                                        <div className={`flex `}>
                                            <p className={`w-[170px] truncate text-left text-[14px] font-normal font-[SansRegular] lowercase pr-1 ${styles.itemsdata}`}>
                                                {item?.createdBy?.email}
                                            </p>
                                            <img src={CopyIcon} height={15} width={15} alt="listitem" priority onClick={() => copyEmail(item?.createdBy?.email)} />
                                        </div>

                                    }
                                </td> */}

                                <td className={`text-left  pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular]`}>{item?.validity}</td>
                                <td className={`text-left text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`}>{item?.planName}</td>
                                <td className={` text-left text-base text-[#1F2533] font-medium font-[Roboto-Medium] }`}>
                                    {item?.price}</td>
                                <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`}>{<ButtonField
                                    buttonName={item.status}
                                    buttonextracls={item.status === 'Expired' ? `!px-3 !py-1 !text-white bg-[#FF6685] text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : item.status === 'Expiring' ? `!px-3 !py-1 !text-white bg-[#FBCB00]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : item.status === 'Active' ? `!px-3 !py-1 !text-white bg-[#1DBF73]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : null}
                                />
                                }
                                </td>
                                <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] `}>
                                    <div className='flex items-center gap-8'>
                                        <img src={editIcon} alt="edit icon" className='text-start' />
                                        <img src={deleteIcon} alt="delete icon" className='text-start' />
                                        <img src={subscriptionAddBtn} alt="add icon" className='text-start' />
                                    </div>

                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default SubscriptionTable

