import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { SUB_ADMIN_API } from '../../Constant';
import { doGetApiCall, doPostApiCall, doPutApiCall, doDeleteApiCall } from "../../Utils/ApiConfig"
import { getTotalCategory } from '../Admin/reducer/CategoryReducer';
import { ForUploadMediaHooks } from '../UploadMedia/Hooks';
import { getServices, getMyRequirements, getOffers, getSingleVendorDetails, getServicesByBusiness, getSingleVendorProductOffers, getReqByVendorId, getBidByReqId, getReviewsByBusIdVendor, getAllInterestedBids, getChats, getServiceOffers, getChatbyChatId, getChatWithbyUserId, getDeniedBidByUserId } from './reducer/VendorReducer';
import moment from 'moment';
import { getBidByUserReqId, getuserDetails } from '../User/reducer/UserReducer';
import { useNavigate } from 'react-router-dom';
import { getDashBoardDataVendor } from '../Admin/reducer/DashboardReducer';
import _ from "lodash";
import { ForSignup } from '../Authentication/Signup/Hook';
import { getLocationReducer } from '../Authentication/Signup/reducer/VendorAccountReducer';

export const ForVendor = () => {

  const { documentUpload, logoUpload, multipleUpload, cleanupMedia } = ForUploadMediaHooks()
  const locationSelector = useSelector((state) => state.vendorAccount.location)

  const navigate = useNavigate()

  const dispatch = useDispatch();
  const [uploadData, setUploadData] = useState(null)
  // Reducer from vendor 
  const singleVendorDetailsData = useSelector((state) => state.vendor.singleVendorDetails)
  const services = useSelector((state) => state.vendor.services)

  // useState for showing service form in service offered tab
  const [showNewServiceForm, setShowNewServiceForm] = useState(false);

  // useState for showing add offer form in pending tab of service offered tab
  const [showAddOfferForm, setShowAddOfferForm] = useState(false);

  // useState for showing my requirements form in my requiremets tab
  const [showMyReqrForm, setShowMyReqrForm] = useState(false);

  const [saveReqData, setSaveReqData] = useState(null);
  const [saveAllReqData, setSaveAllReqData] = useState(null);
  const [isLiveTab, setIsLiveTab] = useState(true);

  const [selectedService, setSelectedService] = useState(null)
  const [editServiceData, setEditServiceData] = useState(null)
  const [changePasswordLoader, setChangePasswordLoader] = useState(false);
  const [editProduct, setEditProduct] = useState(null)

  // For storing the profile details of the vendor who is logged in 
  const [vendorProfileData, setVendorProfileData] = useState(null)

  const userData = useSelector((state) => state.users.userDetailsInUsers)

  const [vendorProfileUpdateTexts, setVendorProfileUpdateTexts] = useState({
    code: "",
    error: false,
    message: ""
  })
  const [categoryId, setCategoryId] = useState([])
  const [subCategoryId, setSubCategoryId] = useState([])
  const [loading, setLoading] = useState(false)

  const [businessLoader, setBusinessLoader] = useState(false)

  // For searching someone in the chatbox's search field
  const [chatSearchString, setChatSearchString] = useState('')

  // tab component value 
  const [value, setValue] = React.useState("0");

  // for conditional rendering of newServiceForm component 
  const toggleNewService = () => {
    setShowNewServiceForm(!showNewServiceForm)
    setEditServiceData(null)
  }
  const goBackToActiveServiceOfferedTab = () => {
    setShowNewServiceForm(!showNewServiceForm)
    setEditServiceData(null)
    cleanupMedia()
  }

  // for conditional rendering of addOfferForm component 
  const toggleAddOffer = (data) => {
    setSelectedService(data)
    setShowAddOfferForm(!showAddOfferForm)
  }

  const goBackToPendingServiceOfferedTab = () => {
    cleanupMedia()
    setShowAddOfferForm(!showAddOfferForm)
  }

  // for conditional rendering of myRequirement component 
  const toggleMyRequirement = () => {
    setShowMyReqrForm(!showMyReqrForm)
  }
  const goBackToMyRequirementTab = () => {
    cleanupMedia()
    setShowMyReqrForm(!showMyReqrForm)
  }

  // state and function for changing in live bid of all requirements
  const [showAllReqrLiveDetails, setShowAllReqrLiveDetails] = useState(false)
  const goToAllReqrLiveDetails = (data, fromLive) => {
    setSaveAllReqData(data)
    setIsLiveTab(fromLive);
    setShowAllReqrLiveDetails(!showAllReqrLiveDetails)
  }
  const goBackFromAllReqrLiveDetails = () => {
    setSaveAllReqData(null)
    setShowAllReqrLiveDetails(!showAllReqrLiveDetails)
  }


  // state and function for changing in live requirements of my requirements
  const [showMyReqrLiveDetails, setShowMyReqrLiveDetails] = useState(false)
  const goToMyReqrLiveDetails = (data) => {
    setSaveReqData(data)
    setShowMyReqrLiveDetails(!showMyReqrLiveDetails)
  }
  const goBackFromMyReqrLiveDetails = () => {
    setSaveReqData(null)
    setShowMyReqrLiveDetails(!showMyReqrLiveDetails)
  }

  // For opening or closing the edit profile form in vendor 
  const [editProfile, setEditProfile] = useState(false)
  const toggleEditProfile = () => {
    setEditProfile(!editProfile)
    cleanupMedia()
    closeOtherTabItems()
  }


  // For submit errors while posting or adding new offers in the service offered pending section
  const [submitError, setSubmitError] = useState({
    code: "",
    error: false,
    message: ""
  })

  // For submit errors while posting or adding new product offers in the offer section
  const [productSubmitError, setProductSubmitError] = useState({
    code: "",
    error: false,
    message: ""
  })

  // For submit errors while posting or adding new product offers in the offer section
  const [serviceSubmitError, setServiceSubmitError] = useState({
    code: "",
    error: false,
    message: ""
  })

  const [serviceSubmitErrorMsg, setServiceSubmitErrorMsg] = useState()



  // For submit errors while posting or adding new product offers in the offer section
  const [reqError, setReqError] = useState({
    code: "",
    error: false,
    message: ""
  })

  // These below three functionalities are only for snackbar of all kinds of form fillup's post api calls in vendor
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    subText: "",
    alertType: "",
    borderClass: "",
  });
  const messageClose = () => {
    setNotification({
      open: false,
      message: "",
      subText: "",
      alertType: "",
      borderClass: "",
    });
  };
  const openFormMessage = (alertType, message, subText, borderClass) => {
    if (alertType) {
      setNotification({
        open: true,
        message: message,
        subText: subText,
        alertType: alertType,
        borderClass: borderClass,
      });
      setTimeout(messageClose, 5000);
    }
  };

  // state and function for changing in service
  const [showOfferForm, setShowOfferForm] = useState(false)
  const toggleOffer = () => {
    setShowOfferForm(!showOfferForm)
    setEditProduct(null)
  }

  const sendData = (data) => {
    setUploadData(data)
  }

  // Use this function to close all other tabs forcefully 
  const closeOtherTabItems = () => {
    setShowNewServiceForm(false)
    setShowAddOfferForm(false)
    setShowMyReqrForm(false)
    setShowMyReqrLiveDetails(false)
    setShowAllReqrLiveDetails(false)
    setShowOfferForm(false)
  }

  //this function is called when any pending service edit btn is clicked
  const editServiceForm = (data) => {
    // console.log(data, '# data')
    setEditServiceData(data)
    setShowNewServiceForm(true)
  }

  // functions related to user profile edit
  const role = localStorage.getItem('role');
  const [editUserProfile, setEditUserProfile] = useState(false);
  const goBackFromUserProfileEdit = () => {
    setEditUserProfile(!editUserProfile)
  }
  const handleUserProfileEdit = () => {
    setEditUserProfile(true)
  }

  //this function is called when any pending product edit button is clicked
  const editProductForm = (data) => {
    setEditProduct(data)
    setShowOfferForm(true)
  }

  const userId = localStorage.getItem('userId')
  const businessId = localStorage.getItem('businessId')

  const getBusiness = async () => {
    setBusinessLoader(true)
    let data = {
      url: `${SUB_ADMIN_API}/business/${userId}`
    }
    let res = await doGetApiCall(data)
    if (!res?.error) {
      localStorage.setItem('businessId', res?.result?.id)
      dispatch(getSingleVendorDetails(res?.result))
      setVendorProfileData(res?.result)
      setBusinessLoader(false)
    } else {
      setBusinessLoader(false)
      dispatch(getSingleVendorDetails({}))
    }
  }

  /**
  *@uplSnehasish
  *@description- This function is used for making api call for posting new vendor service 
  **/
  const addCategoryId = (id) => {
    let idx = categoryId.indexOf(id)
    if (idx > -1) {
      categoryId?.splice(idx, 1)
      setCategoryId([...categoryId])
    } else {
      setCategoryId([id, ...categoryId])
    }
  }
  const addSubCategoryId = (id) => {
    let idx = subCategoryId.indexOf(id)
    if (idx > -1) {
      subCategoryId?.splice(idx, 1)
      setSubCategoryId([...subCategoryId])
    } else {
      setSubCategoryId([id, ...subCategoryId])
    }
  }
  const createVendorPostService = async (formData, selectCategory, selectSubCategory) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/services`,
      bodyData: {
        name: formData.serviceName,
        description: formData.serviceDesc,
        category: selectCategory ? [selectCategory] : [],
        subCategory: selectSubCategory ? [selectSubCategory] : [],
        amount: parseInt(formData.amount),
        // timeFrame: formData.timeFrame,
        businessId: parseInt(businessId),
        // category: categoryId ? categoryId : [],
        // subCategory: subCategoryId ? subCategoryId : [],
        serviceThumbnail: multipleUpload
      }
    }
    let res = await doPostApiCall(data)
    if (!res.error) {
      setLoading(false)
      openFormMessage("success", "Success", res?.message, "success")
      setShowNewServiceForm(!showNewServiceForm)
      getServiceByBusiness()
      setCategoryId('')
      setSubCategoryId('')
      setValue('1')
      setServiceSubmitError({
        code: res?.code,
        error: false,
        message: ""
      })
    } else {
      setLoading(false)
      setServiceSubmitErrorMsg(res)
      openFormMessage("error", "Error", res?.message, "error")
      setServiceSubmitError({
        code: res?.code,
        error: res?.error,
        message: res?.message
      })
    }
  }

  /**
  *@uplSnehasish
  *@description- This function is used for making api call for getting all posted service
  **/
  const getAllPostedService = () => {
    let data = {
      url: `${SUB_ADMIN_API}/services`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getServices(res.result))
      }

    })
      .catch(err => {
        console.log(err, "error")
      })
  }


  /**
  *@uplSnehasish
  *@description- This function is used for making api call for getting all the requirements of all vendors'
  **/
  const getAllOfMyRequirements = (status) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/requirements?dataPerPage=5000&status=${status}`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        setLoading(false)
        dispatch(getMyRequirements(res.result))
      } else {
        setLoading(false)
        dispatch(getMyRequirements([]))
      }

    })
      .catch(err => {
        console.log(err, "error")
      })
  }


  /**
  *@uplSnehasish
  *@description- This function is used for making api call for posting or adding new offers in the service pending section
  **/
  const createNewOffers = (formData) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/offers`,
      bodyData: {
        detail: formData.detail,
        type: formData.type,
        // percentage: formData.percentage ? parseInt(formData.percentage) : null,
        // price: formData.price ? parseInt(formData.price) : null,
        offerTiming: formData.offerTiming,
        // deliveryTime: formData.deliveryTime,
        serviceId: selectedService?.id
      }
    }
    if (formData.type === 'percentage') {
      data.bodyData.percentage = parseInt(formData.percentage)
    } else {
      data.bodyData.price = parseInt(formData.price)
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
          getCreatedNewOffers()
          getServiceByBusiness()
          setShowAddOfferForm(!showAddOfferForm)
        }
        else {
          setLoading(false);
          openFormMessage("error", "Error", res?.message, "error")
          setSubmitError({
            code: res?.code,
            error: res?.error,
            message: res?.message
          })
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
  *@uplRanabir
  *@description- This function is used for making api call for posting or adding new product offers in the offer section
  **/
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      // endDate: addDays(new Date(), 7),
      endDate: new Date(),
      // endDate: null,
      key: 'selection'
    }
  ]);

  const gobackToProductOffer = () => {
    cleanupMedia()
    setShowOfferForm(!showOfferForm)
  }

  const createNewProductOffers = (formData) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/offers`,
      bodyData: {
        productDes: formData.productDetails,
        productName: formData.productName,
        productPrice: parseInt(formData.productActualPrice),
        type: formData.offerType,
        // percentage: formData.percentage ? parseInt(formData.percentage) : null,
        // price: formData.amount ? parseInt(formData.amount) : null,
        offerStartTime: dateState[0].startDate,
        offerEndTime: dateState[0].endDate,
        productImage: multipleUpload,
        businessId: parseInt(businessId)
      }
    }
    if (formData.offerType === 'percentage') {
      data.bodyData.percentage = parseInt(formData.percentage)
    } else {
      data.bodyData.price = parseInt(formData.amount)
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
          getEachVendorProductOffers()
          setShowOfferForm(!showOfferForm)
          setDateState([{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
          }])
          setValue('1')
        }
        else {
          setLoading(false)
          openFormMessage("error", "Error", res?.message, "error")
          setProductSubmitError({
            code: res?.code,
            error: res?.error,
            message: res?.message
          })
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  const backtoPage = () => {
    navigate("/")
    dispatch(getOffers([]))
  }

  /**
 *@uplSnehasish
 *@description- This function is used for making api call for getting all the new offers in the service pending section
 **/
  const offerData = useSelector((state) => state.vendor.offers)
  const serviceOfferData = useSelector((state) => state.vendor.serviceOffers)
  const [hasMore, setHasMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [servicePageNumber, setServicePageNumber] = useState(1)

  const getCreatedNewOffers = () => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/offers?dataPerPage=20&page=${pageNumber}`,
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        setLoading(false)
        const filteredOfferData = res.result?.filter((item) => item?.productId)
        if (pageNumber > 1) {
          dispatch(getOffers([...offerData, ...filteredOfferData]))
        } else {
          dispatch(getOffers(filteredOfferData))
        }
        if (res?.result?.length === 20) {
          setHasMore(true);
        }
        else {
          setHasMore(false)
        }
      } else {
        dispatch(getOffers([]))
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  const increasePageNumber = () => {
    setPageNumber(pageNumber + 1)
  }
  useEffect(() => {
    if (pageNumber > 1) {
      getCreatedNewOffers()
    }
  }, [pageNumber])

  /**
 *@uplRanabir
 *@description- This function is used for making api call to get all the service offers
 **/
  const getCreatedServiceOffers = () => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/offers?type=service&dataPerPage=20&page=${servicePageNumber}`,
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        setLoading(false)
        if (servicePageNumber > 1) {
          dispatch(getServiceOffers([...serviceOfferData, ...res?.result]))
        } else {
          dispatch(getServiceOffers(res?.result))
        }
        if (res?.result?.length === 20) {
          setHasMore(true);
        }
        else {
          setHasMore(false)
        }
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  const increaseServicePageNumber = () => {
    setServicePageNumber(servicePageNumber + 1)
  }

  useEffect(() => {
    if (servicePageNumber > 1) {
      getCreatedServiceOffers()
    }
  }, [servicePageNumber])

  /**
  * @author uplSnehasish   
  * @description This function is used to get product offers of every selected vendor, in the vendor details page.
  **/
  const getEachVendorProductOffers = async (status) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/offers/list/user/${userId}?dataPerPage=200&status=${status}`
    }
    let res = await doGetApiCall(data)
    if (!res?.error) {
      dispatch(getSingleVendorProductOffers(res?.result))
      setLoading(false)
    } else {
      dispatch(getSingleVendorProductOffers([]))
      setLoading(false)
    }
  }

  /**
  *@uplRajdwup
  *@description- This function is used for making api call for getting all posted service based on business
  **/

  const getServiceByBusiness = (status) => {
    if (businessId) {
      setLoading(true)
      let data = {
        url: `${SUB_ADMIN_API}/services/business/${businessId}?status=${status}`
      }
      doGetApiCall(data).then((res) => {
        if (!res.error) {
          dispatch(getServicesByBusiness(res?.result))
          setLoading(false)
        } else {
          dispatch(getServicesByBusiness([]))
          setLoading(false)
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    }
  }

  /**
   * @author uplVikash
   * @method deletePendingServices
   * @description this function is used to delete pending services
   * @param {Object}
   */
  const deletePendingServices = (service) => {
    let data = {
      url: `${SUB_ADMIN_API}/services`,
      bodyData: {
        "services": [
          service?.id
        ]
      }
    }
    doDeleteApiCall(data)
      .then((res) => {
        if (!res.error) {
          getServiceByBusiness()
          openFormMessage("success", "Success", res?.message, "success")
        } else {
          openFormMessage("error", "Error", res?.message, "error")
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
   * @author uplVikash
   * @method editServices
   * @description this function is used to edit the services of the vendor
   */
  const editServices = (serviceData, selectCategory, selectSubCategory) => {

    let data = {
      url: `${SUB_ADMIN_API}/services/${editServiceData?.id}`,
      bodyData: {
        name: serviceData?.serviceName,
        description: serviceData?.serviceDesc,
        // category: categoryId ? categoryId : [],
        // subCategory: subCategoryId ? subCategoryId : [],
        amount: parseInt(serviceData?.amount),
        // timeFrame: serviceData?.timeFrame,
        // serviceDocument: {
        //   name: documentUpload?.originalname ?? documentUpload?.name,
        //   key: documentUpload?.key,
        //   mimetype: documentUpload?.mimetype,
        //   location: documentUpload?.location,
        //   size: documentUpload?.size
        // },
        serviceThumbnail: multipleUpload,
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          dispatch(getServicesByBusiness(res?.result))
          setShowNewServiceForm(!showNewServiceForm)
          getServiceByBusiness()
          setEditServiceData(null)
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }


  /**
   *@uplRanabir
   *@description- This function is used for making api call to get category in vendor 
   **/
  const getCategoryInVendor = async () => {
    let data = {
      url: `${SUB_ADMIN_API}/categories`
    }
    let res = await doGetApiCall(data)
    if (!res.error) {
      dispatch(getTotalCategory(res?.result))
    } else {
    }
  }

  /**
   *@uplRanabir
   *@description- This function is used for making api call to create myRequirements in vendor
   **/
  const myRequirementCreate = (formData) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/requirements`,
      bodyData: {
        title: formData?.title,
        startDate: moment.parseZone(formData?.startDate).utc(true).format(),
        endDate: moment.parseZone(formData?.endDate).utc(true).format(),
        startTime: moment(formData?.startTime).format('HH:mm'),
        endTime: moment(formData?.endTime).format('HH:mm'),
        notify: formData?.type,
        businessId: parseInt(businessId),
        categoryId: formData?.selectCategory,
        subCategoryId: formData?.selectSubCategory,
        // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // timeZone: formData.timeZone,
        description: formData?.description,
        requiredDocument: multipleUpload
      }
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
          setShowMyReqrForm(!showMyReqrForm)
          getVendorRequirementsByVendorId()
          setValue('1')
        } else {
          setLoading(false)
          openFormMessage("error", "Error", res?.message, "error")
          setReqError({
            code: res?.code,
            error: res?.error,
            message: res?.message
          })
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
   *@author uplSnehasish
   *@description- These functions are used for making put api call to edit profile in vendor
   **/

  const editVendorProfileDetailsApi = async (vendorAdminData, latlng) => {
    setLoading(true)
    if ((vendorProfileData?.user?.name !== vendorAdminData?.name && role === 'vendor') || (userData?.name !== vendorAdminData?.name && role === 'user')) {
      let data = {
        url: `${SUB_ADMIN_API}/users/${userId}`,
        bodyData: {
          name: vendorAdminData.name,
          phoneNo: vendorAdminData.phoneNo,
          // lat: latlng?.lat,
          // long: latlng?.lng,
          // location: locationSelector?.length > 0 ? locationSelector : vendorAdminData.location,
        }
      }
      if (role === 'vendor') {
        data.bodyData.lat = latlng?.lat
        data.bodyData.long = latlng?.lng
        data.bodyData.location = locationSelector?.length > 0 ? locationSelector : vendorAdminData.location
      }
      else if (role === 'user') {
        data.bodyData.profilePicture = {
          name: logoUpload?.originalname,
          key: logoUpload?.key,
          mimetype: logoUpload?.mimetype,
          location: logoUpload?.location,
          size: logoUpload?.size
        }
      }
      let res = await doPutApiCall(data)
      if (!res.error) {
        setLoading(false)
        setEditProfile(false)
        if (role === 'user') {
          goBackFromUserProfileEdit()
        }
        else if (role === 'vendor') {
          getBusiness()
        }
        openFormMessage("success", "Success", "Updated successfully", "success")
      }
      else {
        setLoading(false)
        openFormMessage("error", "Error", res?.message[0]?.msg, "error")
      }
    }
    else {
      setLoading(false)
      openFormMessage("warning", "Warning", "Nothing was updated", "warning")
    }
  }


  const editVendorBusinessDetailsApi = (item, location) => {
    let obj = {
      name: vendorProfileData.name,
      governorate: vendorProfileData.governorate,
      places: vendorProfileData.places,
      location: vendorProfileData.location,
      lat: vendorProfileData?.lat,
      lng: vendorProfileData?.long,
      fullAddress: vendorProfileData.address,
      street: vendorProfileData.street,
      block: vendorProfileData.block,
      buildingNo: vendorProfileData.buildingNo,
      floor: vendorProfileData.floor,
      officeNo: vendorProfileData.officeNo,
      startTime: (new Date(["1999-04-06", vendorProfileData?.startTime])),
      endTime: (new Date(["1999-04-06", vendorProfileData?.endTime])),
      date: vendorProfileData.establishmentYear,
    }

    const isKeySameInBothArr = (arr1, arr2, key) => {
      if (arr1.length !== arr2.length) {
        return false; // Arrays have different lengths, keys cannot be equal
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i][key] !== arr2[i][key]) {
          return false; // Key values are not equal in at least one pair of objects
        }
      }
      return true; // All key values are equal in corresponding objects
    }
    const resultForArr = isKeySameInBothArr(multipleUpload, vendorProfileData?.thumbnail, "key");

    if (_.isEqual(obj, item) === false || vendorProfileData?.civilDocument?.key !== documentUpload?.key || vendorProfileData?.logo?.key !== logoUpload?.key || resultForArr === false) {
      setLoading(true)
      let data = {
        url: `${SUB_ADMIN_API}/business/${businessId}`,
        bodyData: {
          name: item.name,
          governorate: item.governorate,
          places: item.places,
          location: locationSelector?.length > 0 ? locationSelector : item.location,
          lat: location?.lat,
          long: location?.lng,
          address: item.fullAddress,
          street: item.street,
          block: item.block,
          buildingNo: item.buildingNo,
          floor: item.floor,
          officeNo: item.officeNo,
          startTime: moment(item.startTime).format('LT'),
          endTime: moment(item.endTime).format('LT'),
          establishmentYear: moment(item.date).year().toString(),
          civilDocument: {
            name: documentUpload?.originalname ?? documentUpload?.name,
            key: documentUpload?.key,
            mimetype: documentUpload?.mimetype,
            location: documentUpload?.location,
            size: documentUpload?.size
          },
          logoPicture: {
            name: logoUpload?.originalname ?? logoUpload?.name,
            key: logoUpload?.key,
            mimetype: logoUpload?.mimetype,
            location: logoUpload?.location,
            size: logoUpload?.size
          },
          businessThumbnail: multipleUpload,
        }
      }
      let res = doPutApiCall(data)
      if (!res.error) {
        setLoading(false)
        getBusiness()
        setEditProfile(false)
        dispatch(getLocationReducer(''))
        openFormMessage("success", "Success", "Updated successfully", "success")
      } else {
        setLoading(false)
        openFormMessage("Error", "Error", "Error", "error")
        setVendorProfileUpdateTexts({
          code: res?.code,
          error: res?.error,
          message: res?.message
        })
      }
    }
    else {
      setLoading(false)
      openFormMessage("warning", "Warning", "Nothing was updated", "warning")
    }
  }


  // To get multiple thumbnail in the vendor page
  const getThumbnailMedia = (key, height, width) => {
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${key}&height=${height}&width=${width}`
    }
    doGetApiCall(data)
      .then((res) => {
        return res
      })
      .catch(err => {
        console.log(err, "error")
      })
  }


  /**
  *@uplSnehasish
  *@description- This function is used for making api call for getting all the requirements of each vendor
  **/
  const getVendorRequirementsByVendorId = (status) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/requirements/user?userId=${userId}&dataPerPage=200&status=${status}`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error && res.status === 200) {
        dispatch(getReqByVendorId(res.result))
        setLoading(false)
      }
      else {
        dispatch(getReqByVendorId([]))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }


  /**
  * @author uplSnehasish
  * @method downloadPdfAttachments
  * @description This function downloads the pdf file attached in the my requirements of vendor page for the users/buyers
  */
  const downloadPdfAttachments = async (docObject) => {
    try {
      let token;
      if (typeof window !== 'undefined') {
        token = localStorage.getItem('token')
      }
      let url = `${SUB_ADMIN_API}/uploads/download/file?key=${docObject?.key}`
      const requestValues = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token || "",
        },
      };
      const res = await fetch(url, requestValues)
      if (res.status !== 200) {
        throw new Error(`Failed to download PDF: ${res.status}`);
      }
      const blob = await res.blob();
      const newBlob = new Blob([blob], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${docObject?.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (err) {
      console.error(err);
    }
  }


  /**   
  *@author uplSnehasish
  *@description- This function is used for making PUT API call to update new password for vendor
  **/

  const changePasswordForVendorApiCall = (formData) => {
    setChangePasswordLoader(true);
    let data = {
      url: `${SUB_ADMIN_API}/users/change/password`,
      bodyData: {
        email: singleVendorDetailsData?.user?.email,
        oldPassword: formData.oldPass,
        newPassword: formData.newPass
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          getBusiness()
          setChangePasswordLoader(false)
          openFormMessage("success", "Success", "Password changed successfully", "success")
          setTimeout(() => {
            if (role === 'user') {
              // navigate('/user')
              goBackFromUserProfileEdit()
            } else {
              navigate('/vendor')
            }
          }, 3000);
        } else {
          setChangePasswordLoader(false);
          if (res?.code === 'PASSWORD_SAME') {
            openFormMessage("error", "Error", 'Old password and New password must be different', "Error")
          } else if (res?.code === 'PASSWORD_CHANGE_ERROR') {
            openFormMessage("error", "Error", 'The old password you entered is wrong', "Error")
          }
          // else {
          //   openFormMessage("error", "Error", res?.message, "Error")
          // }
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting all posted service based on business
  **/

  const getBidApiCAllByReqId = (requirementId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/bids/requirements/${requirementId}`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getBidByReqId(res?.result))
        setLoading(false)
        // dispatch(getBidByUserReqId(res?.result))
      } else {
        dispatch(getBidByReqId([]))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**   
  *@author uplSnehasish
  *@description- This function is used for posting bid
  **/
  const postBid = (formData, requirementsId, businessId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/bids`,
      bodyData: {
        price: parseInt(formData.price),
        requirementsId: requirementsId,
        businessId: businessId
      }
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
          getBidApiCAllByReqId(requirementsId)
          getAllOfMyRequirements()
          getVendorRequirementsByVendorId()
        } else {
          setLoading(false)
          openFormMessage("error", "Error", res?.message, "error")
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  /**
  *@author uplSnehasish
  *@description This function is used to get rating and reviews of the searched vendor
  **/
  const getPostedReviewsInVendor = () => {
    if (businessId) {
      setLoading(true)
      let data = {
        url: `${process.env.REACT_APP_DG_BASE_URL}/reviews/${businessId}?dataPerpage=200`,
      }
      doGetApiCall(data).then((res) => {
        if (!res.error) {
          dispatch(getReviewsByBusIdVendor(res?.result))
          setLoading(false)
        }
        else {
          dispatch(getReviewsByBusIdVendor([]))
          setLoading(false)
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    }
  }

  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting all interested bids
  **/

  const getAllInterestedBidsApiCAll = () => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/interestbids/user/${userId}?dataPerPage=200`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getAllInterestedBids(res?.result))
        setLoading(false)
      } else {
        dispatch(getAllInterestedBids([]))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**   
  *@author uplSnehasish
  *@description- This function is used for posting interested bid
  **/
  const postInterestedBidApiCall = (requirementsId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/interestbids`,
      bodyData: {
        requirementsId: requirementsId,
      }
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error && res?.status === 201) {
          getAllInterestedBidsApiCAll()
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
        } else {
          setLoading(false)
          openFormMessage("error", "Error", res?.message, "error")
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  /**
  * @author uplSnehasish
  * @description this function is used to select bids of vendors in my requirement
  */
  const selectBidsApiCall = (bidId, value, reqId) => {

    let data = {
      url: `${SUB_ADMIN_API}/bids/select/${bidId}`,
      bodyData: {
        select: value
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          getBidApiCAllByReqId(reqId)
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  const getBidByBidIdApiCall = async (bidId) => {
    let data = {
      url: `${SUB_ADMIN_API}/bids/${bidId}`
    }
    let res = await doGetApiCall(data)
  }

  /**
  * @author uplSnehasish
  * @description this function is used to select bids of vendors in my requirement
  */
  const editBidPriceApiCall = (bidId, formData) => {
    let data = {
      url: `${SUB_ADMIN_API}/bids/${bidId}`,
      bodyData: {
        price: parseInt(formData.price)
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          getBidByBidIdApiCall(bidId)
          goBackFromAllReqrLiveDetails()
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  /**
  * @author uplSnehasish
  * @description this function is used to edit product offers in vendor
  */
  const editProductPriceApiCall = (formData, proId) => {
    let data = {
      url: `${SUB_ADMIN_API}/products/${proId}`,
      bodyData: {
        name: formData.name,
        price: parseInt(formData.price)
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {

        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  // for vendor dashboard 
  
  const [dateStateVendorDashboard, setDateStateVendorDashboard] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [dateOpen, setDateOpen] = useState(false)
  const handleOpenDate = () => {
    setDateOpen(!dateOpen)
  }


  const formatStartDate = dateStateVendorDashboard[0]?.startDate?.toISOString()
  const formatEndDate = dateStateVendorDashboard[0]?.endDate?.toISOString()

  /**
* @uplRanabir
* @description- this function is used for making api call to get dashboard data
*/
  const getDashboardDataVendorApi = () => {
    if (businessId) {
      let data;
      if (dateStateVendorDashboard[0]?.startDate && dateStateVendorDashboard[0]?.endDate) {
        data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/business/report/details/${businessId}?startDate=${formatStartDate}&endDate=${formatEndDate}`
        }
      } else {
        data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/business/report/details/${businessId}`
        }
      }
      doGetApiCall(data).then((res) => {
        if (!res?.error) {
          dispatch(getDashBoardDataVendor(res?.result))
          dateOpen === true && setDateOpen(!dateOpen)
          // setDateState(dateState[0]?.startDate === new Date(), dateState[0]?.endDate === new Date())
        } else {
          dispatch(getDashBoardDataVendor({}))
          setDateOpen(!dateOpen)
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    }
  }

  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting chats
  **/
  const getChatsApiCAll = () => {
    setLoading(true)
    let data;
    if (chatSearchString) {
      data = {
        url: `${SUB_ADMIN_API}/chats?string=${chatSearchString}&dataPerPage=500`
      }
    } else {
      data = {
        url: `${SUB_ADMIN_API}/chats?dataPerPage=500`
      }
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getChats(res?.result))
        setLoading(false)
      } else {
        dispatch(getChats([]))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
  * @author uplSnehasish
  * @description this function is used to debounce search results for chatbox.
  */
  const handleSearchString = (data) => {
    setChatSearchString(data)
  }
  const debounceSearch = _.debounce(handleSearchString, 500)
  const handleSearchChat = (e) => {
    debounceSearch(e.target.value)
  }


  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting chats
  **/
  const getChatbyChatIdApiCAll = (chatId) => {
    setLoading(true)
    if (chatId === null) {
      dispatch(getChatbyChatId([]))
    } else {
      let data = {
        url: `${SUB_ADMIN_API}/chats/messages/${chatId}?dataPerPage=5000`
      }
      doGetApiCall(data).then((res) => {
        if (!res.error) {
          dispatch(getChatbyChatId(res?.result))
          setLoading(false)
        } else {
          dispatch(getChatbyChatId([]))
          setLoading(false)
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    }

  }

  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting chats
  **/
  const getChatDetailsWithUserApiCAll = (userId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/chats/details/with/${userId}`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getChatWithbyUserId(res?.result))
        setLoading(false)
      } else {
        dispatch(getChatWithbyUserId({}))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })

  }

  /**   
  *@author uplSnehasish
  *@description- This function is used for chatting with others
  **/
  const postChatApiCall = (message, userId, chatId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/chats`,
      bodyData: {
        message: message,
        // chatId: parseInt(chatId),
        userId: parseInt(userId)
      }
    }
    if (multipleUpload && multipleUpload?.length < 2) {
      data.bodyData.file = multipleUpload[0]
    }

    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          getChatbyChatIdApiCAll(res?.result?.chatId)
          getChatsApiCAll()
          cleanupMedia()
        } else {
          getChatbyChatIdApiCAll(null)
        }
      })
      .catch((err) => {
        getChatbyChatIdApiCAll(null)
        console.error(err)
      });
  }

  /**   
 *@author uplSnehasish
 *@description- This function is used for chatting with others
 **/
  const chatMessageReadApi = (chatId) => {
    let data = {
      url: `${SUB_ADMIN_API}/chats/read/status`,
      bodyData: {
        chatId: parseInt(chatId),
      }
    }

    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          getChatsApiCAll()
        } else {
          getChatsApiCAll()
        }
      })
      .catch((err) => {
        // getChatbyChatIdApiCAll(null)
        console.error(err, "Message read API error")
      });
  }

  /**
  * @author uplSnehasish
  * @method downloadImage
  * @description This function downloads the image
  */
  const downloadImage = async (key) => {
    try {
      let token;
      if (typeof window !== 'undefined') {
        token = localStorage.getItem('token')
      }
      let url = `${SUB_ADMIN_API}/uploads/download/file?key=${key}`
      const requestValues = {
        method: "GET",
        headers: {
          Authorization: token || "",
        },
      };
      const res = await fetch(url, requestValues)
      const blob = await res.blob();
      const newBlob = new Blob([blob], { type: "image/jpeg" });
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${key}.jpeg`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (err) {
      console.error(err);
    }
  }

  /**   
    *@author uplSnehasish
    *@description- This function is used for creating declined requirements
    **/
  const postDeclinBid = (requirementsId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/deniedbids`,
      bodyData: {
        requirementsId: requirementsId,
      }
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          setLoading(false)
          openFormMessage("success", "Success", res?.message, "success")
          getAllOfMyRequirements()
          getVendorRequirementsByVendorId()
          getDeniedBidByUserIdApiCall(userId)
        } else {
          setLoading(false)
          openFormMessage("error", "Error", res?.message, "error")
        }
      })
      .catch((err) => {
        console.error(err)
      });
  }

  /**
  *@author uplSnehasish
  *@description- This function is used for making api call for getting denied requirements
  **/
  const getDeniedBidByUserIdApiCall = (userId) => {
    setLoading(true)
    let data = {
      url: `${SUB_ADMIN_API}/deniedbids/user/${userId}?dataPerPage=1000`
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        dispatch(getDeniedBidByUserId(res?.result))
        setLoading(false)
      } else {
        dispatch(getDeniedBidByUserId([]))
        setLoading(false)
      }
    })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
   * @author uplSnehasish
   * @method deleteProductOffers
   * @description this function is used to delete active/pending product offers
   */
  const deleteProductOffers = (product) => {
    if (product && Object?.keys(product)?.length > 0) {
      let data = {
        url: `${SUB_ADMIN_API}/offers`,
        bodyData: {
          "offers": [
            product?.id
          ]
        }
      }
      doDeleteApiCall(data)
        .then((res) => {
          if (!res?.error) {
            getEachVendorProductOffers()
            openFormMessage("success", "Success", res?.message, "success")
          } else {
            openFormMessage("error", "Error", res?.message, "error")
          }
        })
        .catch(err => {
          console.log(err, "error")
        })
    }
  }

  return {
    createVendorPostService,
    getAllPostedService,
    getAllOfMyRequirements,
    uploadData,
    sendData,
    // for new service form in new service tab 
    toggleNewService,
    showNewServiceForm,
    setShowNewServiceForm,
    goBackToActiveServiceOfferedTab,
    // for add offer form in pending section of service offered tab 
    showAddOfferForm,
    setShowAddOfferForm,
    toggleAddOffer,
    goBackToPendingServiceOfferedTab,
    createNewOffers,
    getCreatedNewOffers,
    // for the form of my requirements tab 
    goBackToMyRequirementTab,
    toggleMyRequirement,
    showMyReqrForm,
    setShowMyReqrForm,
    // for my requirements tab 
    goToMyReqrLiveDetails,
    goBackFromMyReqrLiveDetails,
    showMyReqrLiveDetails,
    setShowMyReqrLiveDetails,
    getVendorRequirementsByVendorId,
    saveReqData,
    // for all requirements tab 
    goToAllReqrLiveDetails,
    goBackFromAllReqrLiveDetails,
    showAllReqrLiveDetails,
    setShowAllReqrLiveDetails,
    saveAllReqData,
    closeOtherTabItems,
    setSelectedService,
    selectedService,
    getBusiness,
    submitError,
    singleVendorDetailsData,
    // for service which is Completedly new 
    showOfferForm,
    toggleOffer,
    createNewProductOffers,
    dateState,
    setDateState,
    gobackToProductOffer,
    getEachVendorProductOffers,
    getServiceByBusiness,
    deletePendingServices,
    editServices,
    editServiceForm,
    editServiceData,
    addCategoryId,
    addSubCategoryId,
    myRequirementCreate,
    getCategoryInVendor,
    productSubmitError,
    serviceSubmitError,
    toggleEditProfile,
    editProfile,
    editVendorProfileDetailsApi,
    editVendorBusinessDetailsApi,
    vendorProfileData,
    vendorProfileUpdateTexts,
    getThumbnailMedia,
    downloadPdfAttachments,
    changePasswordForVendorApiCall,
    postBid,
    getBidApiCAllByReqId,
    loading,
    getPostedReviewsInVendor,
    value,
    setValue,
    postInterestedBidApiCall,
    getAllInterestedBidsApiCAll,
    selectBidsApiCall,
    reqError,
    notification,
    messageClose,
    editBidPriceApiCall,
    serviceSubmitErrorMsg,
    editProductPriceApiCall,
    dateStateVendorDashboard,
    setDateStateVendorDashboard,
    getDashboardDataVendorApi,
    handleOpenDate,
    dateOpen,
    setDateOpen,
    postChatApiCall,
    getChatsApiCAll,
    getChatbyChatIdApiCAll,
    getChatDetailsWithUserApiCAll,
    downloadImage,
    // handlePageNumber,
    hasMore,
    increasePageNumber,
    backtoPage,
    goBackFromUserProfileEdit,
    editUserProfile,
    handleUserProfileEdit,
    getCreatedServiceOffers,
    increaseServicePageNumber,
    editProductForm,
    editProduct,
    chatMessageReadApi,
    handleSearchChat,
    chatSearchString,
    postDeclinBid,
    getDeniedBidByUserIdApiCall,
    deleteProductOffers,
    isLiveTab,
    changePasswordLoader,
    businessLoader
  }
}
