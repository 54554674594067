import React from 'react'
import AdminSideBar from './AdminSideBar'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom'
import { ForNotification } from '../../Containers/Notification/Hooks';
import { useEffect } from 'react';


const AdminIndex = (props) => {
    const { backtohomePage, getNotification, readNotificationApi, handleshow, showNotification, allnotification, detailsPage } = ForNotification()

   
    useEffect(() => { getNotification() }, [])
    return (
        <div>
            <LandingHeader 
                landingRoot={`fixed top-0 z-50`}
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true} 
                handleclickNotification={handleshow}
                showNotification={showNotification}
                allnotification={allnotification}
                detailsPage={detailsPage}
                readNotificationApi={readNotificationApi}
                getNotification={getNotification}
            />
            <div className={`flex w-full h-screen pt-[5.625rem]`}>
                <div className={`!w-[15%] bg-white`}>
                    <AdminSideBar/>
                </div>
                <div className='!w-[85%] bg-light-gray'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

AdminIndex.propTypes = {
    subAdminCreate: PropTypes.func,
    getAllUsers: PropTypes.func,
    subAdminUser: PropTypes.string,
    showSubAdminForm: PropTypes.bool,
    goBackToTable: PropTypes.func,
    toggleSubAdmin: PropTypes.func,
    setShowSubAdminForm: PropTypes.func,
}

export default AdminIndex